import { css } from '@emotion/react'
import styled from '@emotion/styled'
import type { CustomerSubscriptionSummary } from '@orus.eu/backend/src/views/subscriptions/subscription-view'
import { TechnicalError } from '@orus.eu/error'
import {
  Button,
  RowButtonV2,
  RowContainerV2,
  Text,
  colors,
  mobileMediaQuery,
  spacing,
  useAsyncCallback,
  useTranslate,
} from '@orus.eu/pharaoh'
import { ContentContainerAppClient, FlexColumn, PageTitle } from '@orus.eu/pharaoh/src/components/atoms'
import { ContractProduct } from '@orus.eu/pharaoh/src/components/features/multi-contracts/contract-product'
import { EmptyElement } from '@orus.eu/pharaoh/src/components/features/multi-contracts/empty-element'
import { useNavigate } from '@tanstack/react-router'
import { memo, useCallback } from 'react'
import { v4 } from 'uuid'
import { trpc, trpcReact } from '../../client'
import { capitalizeFirstLetter, formatDateTime } from '../../lib/format'
import { useNavigateTo } from '../../lib/hooks/use-navigate-to-route'
import { useNavigateToSubscriptionFunnel } from '../../lib/hooks/use-navigate-to-subscription-funnel'
import { SubscriptionStatusChip } from '../molecules/subscription-status-chip'
import { SmallScreenMargin } from '../templates/small-screen-margin'
import {
  getUserContractsByActivitiesAndAddresses,
  type UserContractsByActivitiesAndAddresses,
} from './contracts-by-activity-and-address'
import {
  productsAvatarConfigurations,
  productsShortNames,
} from './subscription-v2/elements/quote-page-body-element/product-information-util'

const GuaranteeListContainer = styled(RowContainerV2)`
  & {
    padding-left: 0;
    padding-right: 0;
    ${mobileMediaQuery} {
      border-radius: 0;
    }
  }
`

const ContractsPage = memo(function ContractsPage(): JSX.Element {
  const translate = useTranslate()
  const [contracts] = trpcReact.contracts.listMyContracts.useSuspenseQuery()
  const [subscriptions] = trpcReact.subscriptions.listMyUnsignedSubscriptions.useSuspenseQuery()
  const navigateToSubscriptionFunnel = useNavigateToSubscriptionFunnel()

  const createSubscription = useAsyncCallback(async () => {
    const result = await trpc.selfOnboarding.createSubscriptionAsExternalUser.mutate({
      idempotencyKey: v4(),
    })

    if (result.type === 'failure') {
      throw new TechnicalError('Error while creating subscription as external user', {
        context: { err: result.problem },
      })
    }
    navigateToSubscriptionFunnel(result.output.subscriptionId)
  }, [navigateToSubscriptionFunnel])

  if (contracts.length === 0) {
    return (
      <SmallScreenMargin>
        <Text variant="body1">Vous n&apos;avez pas de contrat actif.</Text>
      </SmallScreenMargin>
    )
  }

  const userContractsByActivitiesAndAddresses = getUserContractsByActivitiesAndAddresses(contracts)

  return (
    <AllPageHeight>
      <PageTitle
        title={translate('my_contracts')}
        appendRightOnDesktop={
          <Button variant="primary" icon="plus-regular" avatarPosition="left" onClick={createSubscription}>
            {translate('new_contract')}
          </Button>
        }
      />

      <ContentContainerAppClientWithMobileButtonOnBottom>
        <ContractsByActivitiesAndAddresses
          userContractsByActivitiesAndAddresses={userContractsByActivitiesAndAddresses}
          hideLegalProtection
          displayContractNumber
        />

        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: ${spacing[50]};
          `}
        >
          <SmallScreenMargin>
            <Text variant="subtitle2">{translate('my_quotes')}</Text>
          </SmallScreenMargin>

          {subscriptions.length === 0 ? (
            <EmptyElement text={translate('no_pending_quote')} />
          ) : (
            <GuaranteeListContainer>
              {subscriptions.map((subscription) => {
                return <SubscriptionRowButton key={subscription.id} subscription={subscription} />
              })}
            </GuaranteeListContainer>
          )}
        </div>
        <SmallScreenMargin>
          <OnlyVisibleOnMobileCTA
            variant="primary"
            icon="plus-regular"
            avatarPosition="left"
            onClick={createSubscription}
            size="large"
            fullWidth
          >
            {translate('new_contract')}
          </OnlyVisibleOnMobileCTA>
        </SmallScreenMargin>
      </ContentContainerAppClientWithMobileButtonOnBottom>
    </AllPageHeight>
  )
})

const AllPageHeight = styled(FlexColumn)`
  min-height: 100%;
  margin: 0;
  padding: 0;
  gap: 0;
  display: flex;
  flex-direction: column;
`

const ContentContainerAppClientWithMobileButtonOnBottom = styled(ContentContainerAppClient)`
  flex-grow: 1;

  & > :last-child {
    margin-top: auto;
  }
`

const OnlyVisibleOnMobileCTA = styled(Button)`
  display: none;
  margin: ${spacing[60]} 0;
  ${mobileMediaQuery} {
    display: flex;
  }
`

type SubscriptionRowButtonProps = { subscription: CustomerSubscriptionSummary }
const SubscriptionRowButton = memo<SubscriptionRowButtonProps>(function SubscriptionRowButton({ subscription }) {
  const handleClick = useNavigateTo({
    to: '/subscribe/$subscriptionId/$stepId',
    params: { subscriptionId: subscription.id, stepId: 'quote' },
  })

  return (
    <RowButtonV2
      key={subscription.id}
      avatarRight={<SubscriptionStatusChip status={subscription.status} />}
      primaryText={getSubscriptionName(subscription)}
      secondaryText={subscription.activityName}
      tertiaryText={subscription.companyAddress}
      onClick={handleClick}
    />
  )
})

type ContractsByActivitiesAndAddressesProps = {
  userContractsByActivitiesAndAddresses: UserContractsByActivitiesAndAddresses[]
  hideLegalProtection?: boolean | undefined
  displayContractNumber: boolean
  navigateToGuaranteePage?: boolean | undefined
}

export const ContractsByActivitiesAndAddresses = memo<ContractsByActivitiesAndAddressesProps>(
  function ContractsByActivityAndAddress(props) {
    const {
      userContractsByActivitiesAndAddresses,
      hideLegalProtection,
      displayContractNumber,
      navigateToGuaranteePage,
    } = props
    const navigate = useNavigate()

    const onContractClick = useCallback(
      (subscriptionId: string, productName: string) => {
        if (navigateToGuaranteePage) {
          void navigate({
            to: '/product-guarantees/$subscriptionId/$productName',
            params: { subscriptionId, productName },
          })
          return
        }
        void navigate({ to: '/contract/$subscriptionId', params: { subscriptionId } })
      },
      [navigate, navigateToGuaranteePage],
    )

    return userContractsByActivitiesAndAddresses.map((userContractsByActivityAndAddress) => {
      return (
        <div key={userContractsByActivityAndAddress.activity.displayName}>
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <SmallScreenMargin>
              <Text variant="subtitle2">{userContractsByActivityAndAddress.activity.displayName}</Text>
              <Text
                variant="body2"
                color={colors.gray[800]}
                css={css`
                  margin-top: ${spacing[20]};
                  margin-bottom: ${spacing[50]};
                `}
              >
                {userContractsByActivityAndAddress.companyAddress}
              </Text>
            </SmallScreenMargin>

            <GuaranteeListContainer variant="mobile">
              {userContractsByActivityAndAddress.contracts &&
                userContractsByActivityAndAddress.contracts.map((contract) => {
                  return (
                    contract.productsInformation &&
                    contract.productsInformation.map((productInformation) => {
                      if (hideLegalProtection && productInformation.product === 'rcph-pj') return
                      return (
                        <ContractProduct
                          key={productInformation.product}
                          productInformation={productInformation}
                          productsAvatarConfigurations={productsAvatarConfigurations[productInformation.product]}
                          currentProtectionStatus={contract.currentProtectionStatus}
                          contractNumber={contract.contractNumber}
                          displayContractNumber={displayContractNumber}
                          onClick={() => onContractClick(contract.subscriptionId, productInformation.name)}
                        />
                      )
                    })
                  )
                })}
            </GuaranteeListContainer>
          </div>
        </div>
      )
    })
  },
)

export default ContractsPage

function getSubscriptionName(subscription: CustomerSubscriptionSummary): string {
  if (subscription.selectedActivableProductInformationIds.length > 0) {
    return subscription.selectedActivableProductInformationIds
      .map((id) => productsShortNames[id])
      .map(capitalizeFirstLetter)
      .join(' + ')
  }

  return formatDateTime(subscription.creationTimestamp)
}

import { css } from '@emotion/react'

import { memo } from 'react'
import { Text, colors, spacing } from '../../../../index.js'
import { Avatar } from '../../../atoms/avatar/avatar.js'

export const EmptyElement = memo(function SectionProduct(props: { text: string }) {
  return (
    <div
      css={css`
        background-color: ${colors.blue[100]};
        display: flex;
        flex-direction: column;
        padding: ${spacing[60]};
        height: 100px;
        align-items: center;
        justify-content: center;
        gap: ${spacing[30]};
      `}
    >
      <Avatar icon="file-light" size="40" color={colors.gray[700]} />
      <Text variant="body2">{props.text}</Text>
    </div>
  )
})

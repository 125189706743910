import type { ActivityInput } from '@orus.eu/activity'
import type { CustomerContractDescription } from '@orus.eu/backend/src/views/customer-contract-view'

export type UserContractsByActivitiesAndAddresses = {
  activity: ActivityInput
  companyAddress: string
  contracts: CustomerContractDescription[]
}

export function getUserContractsByActivitiesAndAddresses(
  contracts: CustomerContractDescription[],
): UserContractsByActivitiesAndAddresses[] {
  const userContractsByActivitiesAndAddresses: UserContractsByActivitiesAndAddresses[] = []

  contracts.forEach((contract) => {
    const index = userContractsByActivitiesAndAddresses.findIndex(
      (userContractsByActivityAndAddress) =>
        userContractsByActivityAndAddress.activity === contract.activity &&
        userContractsByActivityAndAddress.companyAddress === contract.companyAddress,
    )
    if (index === -1) {
      userContractsByActivitiesAndAddresses.push({
        activity: contract.activity,
        companyAddress: contract.companyAddress,
        contracts: [contract],
      })
    } else {
      userContractsByActivitiesAndAddresses[index].contracts.push(contract)
    }
  })
  return userContractsByActivitiesAndAddresses
}
